import React from 'react';
import { Link } from 'react-router-dom';
import './Programs.css';
import image4 from '../images/sven.jpg';
import image2 from '../images/atee.jpeg';
import image from '../images/papy.png';
import image5 from '../images/kim.jpg';

const Programs = () => {
  const moveToSection = (id) => {
    const element = document.getElementById(id);
    if (element) { // Check if the element exists in the DOM
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <section className="programs">

      <div className="progContainer">
        <h2 className="programs-headline">Our Programs</h2>
        <span className='progText'>
          Explore our  courses for beginners, intermediates, and advanced learners to help you achieve your career goals.
        </span>
       
        <button className='btncourse'>Download Our Master Course Packet</button>
<div className="allprograms">
        <div className="progRight">


          <div className="program">
            <div className='progTitle'><h3>Web Development Fundamentals Bootcamp</h3>
              <h4>5 Weeks + 2 Projects  <br></br>|Total 88 Hours</h4>
            </div>
            <div className='progDetail'>
              Learn the fundamentals of HTML, including web page structure, elements, attributes,
              and document hierarchy. Master CSS essentials, such as styling web pages, properties,
              selectors, and units, and gain a foundational understanding of JavaScript.

              <p>Commit to a four-week program and seek support from friends and family. Bring your
                own laptop for workshops and coding practice. No prior experience with HTML, CSS,
                or Bootstrap is required. By the end of the course,
                you will have built your first website and completed at least two web projects.</p>
            </div>
          </div>
          <div className="program0">
            <div className='progTitle'> <h3>Front-End Boot Camp <br></br>Web & Mobile Apps</h3>
              <p><h4>14 Weeks + 3 Project Weeks | 340 Hours</h4></p></div>
            <div className='progDetail'> <p>


              Master JavaScript , data structures and algorithms.
              You'll learn to manipulate the browser DOM and make asynchronous API calls.<br></br>

              Build websites with React, React Hooks, and  Redux .
              <p></p>
              Develop multiplatform Mobile App with React Native.
              Build native mobile apps with React Native and Expo SDK.
              Publish your app to the Apple Store or Google Play, leveraging native hardware capabilities.
              <p></p>
              Online lectures and exercises and weekly graded workshop assignment


              build a portfolio project to add to your resumes.
            </p>
            </div>
          </div>

          <div className="program1">

            <div className='progTitle'> <h3>Full-Stack Web & Mobile Developer Program</h3>
              <p><h4>22 Weeks | 16 Projects 436 Hours</h4></p></div>
            <div className='progDetail'> <p>
              Start your career as
              a Full Stack Web and Mobile Developer.
              You'll work on 16 projects that solidify
              your understanding and application of cutting-edge technologies
              <p>

                Master React,  building Single Page Applications.
                Create dynamic, user-friendly interfaces.
                Mobile Development with React Native
                build mobile applications for Android, iPhone

                Handle database with Node.js, Google Cloud & Firebase

                Learn MongoDB for efficient data storage.
                <p></p>
                Develop complex applications
                Collaborate on projects using GitHub.
                <p></p>
              </p>
            </p></div>
          </div>


          <div className="program2">
            <div className='progTitle'>
              <h3>Back-End, SQL, and DevOps with Python Bootcamp</h3>
              <p><h4>Embark on a 16-week  to master back-end development</h4></p></div>
            <div className='progDetail'> <p>

              expertise in back-end development, SQL databases, and DevOps practices,
              ready to advance in the tech industry.<p></p>
              Learn Python for websites, and data science projects,

              Utilize PostgreSQL, to manage and scale complex data workloads.
              <br></br>
              Develop, ship, and run applications using Docker.
              Start with Flask and Django.


              Learn Agile, Waterfall, and Spiral models.
              Continuous Integration and Continuous Delivery (CI/CD)
              Amazon Web Services (AWS)

              Utilize AWS for on-demand cloud computing
              Leverage Microsoft's cloud computing service .
              Google Cloud/Firestore


            </p>
            </div>


          </div>
        </div>
        </div>
        <div>
       

          <div className="program12">
            <div className='progTitle'>
              <h3>Data Analyst with Power BI and Tableau</h3>
              <p><h4>8 Weeks to dive into the world of data analysis and enhance your career prospects. </h4></p></div>
            <div className='progDetail'>
             
              As Data analyst you will Analyze and interpret complex data sets to help organizations make informed decisions.
              Business Intelligence Analyst: Develop reports and dashboards to visualize business performance and trends.
             <p></p>Data Visualization Specialist: Create compelling visualizations and dashboards to present data insights effectively.
              Reporting Analyst: Generate detailed reports and analyze data to support business operations and strategy.

              Introduction to Data Analysis: Understand key concepts and the role of data analysts.
              <p></p> With Power BI: Learn to connect to data sources, create interactive reports and dashboards, and publish your work.
              <p></p>With Tableau: Gain expertise in connecting to data, building visualizations, and sharing your insights.
              <p></p>Case Studies: Apply your knowledge through real-world projects and case studies.

              <p></p> Job Opportunities After Completion:<br></br>
              A significant number of job postings for data analysts, business analysts, and similar roles list proficiency in Power BI or Tableau as a required or preferred skill.
              
            </div>
         
          </div>

          <span className='progText'>
            <div className="programs-headline">Gain exceptional knowledge from most motivated and experienced
              instructor! </div>
            With a proven track record and market appreciation, our instructor will guide you every
            step of the way.<br></br>
            It's time for you to enjoy your work. Our mentoring
            and personalized support will help you learn quickly and overcome any
            coding challenges you face.<p></p>Enjoy hands-on mentorship and expert
            insights, ensuring you acquire
            practical
            skills and real-world experience.
          </span>

   </div>

          <div className='progLeft'>


            <div className='inst'>
              <img className="imLeft" src={image} alt="images" />
              <div className='instName'>Engineer Papy M.</div>
              <div className='instrTitle'> Co-Founder Code4All <br></br> Bs Information Technology<br></br> University of Phoenix Arizona</div>
            </div>

            <div className='inst'>
              <img className="imLeft" src={image5} alt="images" />
              <div className='instName'>Engineer Storm R </div>
              <div className='instrTitle'>Expert Full Stack developer<br></br>Bs Information Technology<br></br> State University of New York</div>
            </div>
            <div className='inst'>
              <img className="imLeft" src={image4} alt="images" />
              <div className='instName'>Engineer Sven Esser</div>
              <div className='instrTitle'> 10 years Experiences <br></br>
                CEO Sven <br></br>Limburg, Netherlands</div>
            </div>
            <div className='inst'>
              <img className="imLeft" src={image2} alt="images" />
              <div className='instName'>Engineer ATEESH K.</div>
              <div className='instrTitle'>Full Stack Developer<br></br> Bs in Computer Science <br></br>SealStone UK</div>
            </div>



          </div>




        </div>


    </section>
  );
};

export default Programs;