import React from 'react';
import './Faq.css';

const Faq = () => {
  return (
    <section className="faq" id="faq">
      <div className="container">
        <h2 className="faq-headline">Frequently Asked Questions</h2>
        <div className="faq-accordion">
          <div className="faq-item">
            <button className="faq-question">What is the duration of the program?</button>
            <div className="faq-answer">
              <p>The program duration is from 6 weeks <bt></bt>24weeks</p>
            </div>
          </div>
          <div className="faq-item">
            <button className="faq-question">What are the prerequisites?</button>
            <div className="faq-answer">
              <p>There are no prerequisites...</p>
            </div>
          </div>
          <div className="faq-item">
            <button className="faq-question">What is the job placement rate?</button>
            <div className="faq-answer">
              <p>At Code for All, we don't make false promises. We're committed to transforming you into a high-tech coding expert. You'll work on multiple projects, enhancing your visibility and enabling you to create your own opportunities. Your final project will involve building an application for a local small business, which you can present to the client. Our goal is to make you both a job hunter and a job creator..</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faq;