// components/Signup.js
import { useState } from 'react';
import React from 'react';
import { useSignup } from '../hooks/useSignup';

function Signup() {
  const [fname, setFname] = useState('');
  const [lname, setLname] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { signup, isPending, error } = useSignup();

  const handleClick = (e) => {
    e.preventDefault();
    signup(email, password, fname, lname);
  };

  return (
    <form onSubmit={handleClick} className="signup_form">
      <label>
        <span>First Name</span>
        <input
          type="text"
          value={fname}
          placeholder="First Name"
          onChange={(e) => setFname(e.target.value)}
        />
      </label>

      <label>
        <span htmlFor="lname">Last Name</span>
        <input
          type="text"
          value={lname}
          placeholder="Last Name"
          onChange={(e) => setLname(e.target.value)}
        />
      </label>

      <label>
        <span htmlFor="email">Email</span>
        <input
          type="email"
          value={email}
          placeholder="Email"
          onChange={(e) => setEmail(e.target.value)}
        />
      </label>

      <label>
        <span htmlFor="password">Password</span>
        <input
          type="password"
          value={password}
          placeholder="Password"
          onChange={(e) => setPassword(e.target.value)}
        />
      </label>

      {!isPending && <button className="signup_btn" type="submit">Signup</button>}
      {isPending && <button className="signup_btn" type="submit" disabled>Loading...</button>}
      {error && <p>{error}</p>}
    </form>
  );
}

export default Signup;
