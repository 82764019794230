import React from 'react';
import './Footer.css';
import {useNavigate } from 'react-router-dom';

const Footer = () => {
  const navigate = useNavigate();

  const handlegoToAdmin = () => {
    // Navigate to the LearningPage component
    navigate('/admin-login');
  };
  
  return (
    <footer className="footer">
      <div className="container">
        <p>&copy; 2023 Code For All Bootcamp. All rights reserved. by P. Mulongo</p>
        <nav className="footer-nav">
          <a href="/">Home</a>
          <a href="./about">About</a>
          <a href="./programs">Programs</a>
          <a href="#testimonials">Testimonials</a>
          <a href="#contact">Contact</a>
          <a href="#contact">--</a>
        </nav>
        <p onClick={handlegoToAdmin}>admin</p>
      </div>
    </footer>
  );
};

export default Footer;