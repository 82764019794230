// src/class/login.js

import React, { useState } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../firebase'; // Ensure the correct path to your firebase.js file
import { useNavigate } from 'react-router-dom';
import "./Login.css"

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const moveToSection = ()=>{
    navigate("/");
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      // Query to find documents where both username and password match
      const q = query(
        collection(db, 'formbootcamp/StudentAuth/authentication'),
        where('username', '==', username),
        where('password', '==', password)
      );
      const querySnapshot = await getDocs(q);
      
      if (!querySnapshot.empty) {
        // Assuming only one document should match the credentials
        const userDoc = querySnapshot.docs[0].data();
        const level = userDoc.level;

        // Store user session (you might use context, local storage, etc.)
        localStorage.setItem('username', username);
        localStorage.setItem('level', level);

        // Redirect to class page
        alert('valid credentials');
        navigate('/learningpage');

      } else {
        alert('Invalid credentials');
      }
    } catch (error) {
      console.error('Error checking credentials:', error);
      alert('Error during login. Please try again.');
    }
  };

  return (
    <div className="login">
      <h2>Student Login <br></br>Reserved Space</h2>
      <p className='studentCred'>Please enter your credentials
      To access the classroom. <br></br>If you are not enrolled yet please <span className="navItems"  onClick={() => moveToSection('enroll')}>click Here To Enroll</span></p>
      <form className= "formLogin" onSubmit={handleSubmit}>
        <label>
          User name:
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </label>
        <label>
          Password:
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </label>
        <button type="submit">Login</button>
      </form>
    </div>
  );
};

export default Login;
