import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Hero from './components/Hero';
import About from './components/About';
import Programs from './components/Programs';
import Welcome from './components/Welcome';
import CallToAction from './components/CallToAction';
import Faq from './components/Faq';
import Footer from './components/Footer';
import ProgramForm from './components/ProgramForm';
import ScheduleCallForm from './components/ScheduleCallForm';
import Login from './class/Login';
import Signup from './components/Signup';
import LearningPage from './class/learningPage';
import AdminLogin from "./components/AdminLogin" // Import the AdminLogin component
import AdminDashboard from './components/AdminDashboard'; // Import the AdminDashboard component
import ProtectedRoute from './components/ProtectedRoute'; // Import the ProtectedRoute component

function MainLayout() {
  return (
    <div className='MainLayout'>
      <Header />
      <div id="welcome"><Welcome /></div>
      <div id="hero"><Hero /></div>
      <div id="about"><About /></div>
      <div id="cta"><CallToAction /></div>
      <div id="programs"><Programs /></div>
      <div id="enroll"><ProgramForm /></div>
      <div id="scheduleCall"><ScheduleCallForm /></div>
      <div id="faq"><Faq /></div>
      
      <Footer />
    </div>
  );
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainLayout />} />
        <Route path="/login" element={<Login />} />
        <Route path="/learningpage" element={<ProtectedRoute element={<LearningPage />} />} />
        <Route path="/admin-login" element={<AdminLogin />} /> {/* Route for the admin login */}
        <Route path="/admin" element={<AdminDashboard />} /> {/* Route for the admin dashboard */}
      </Routes>
    </Router>
  );
}

export default App;

/*import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Hero from './components/Hero';
import About from './components/About';
import Programs from './components/Programs';
import Welcome from './components/Welcome';
import CallToAction from './components/CallToAction';
import Faq from './components/Faq';
import Footer from './components/Footer';
import NewProgramForm from './components/NewProgramForm';
import ScheduleCallForm from './components/ScheduleCallForm';
import Login from './class/Login';
import LearningPage from './class/learningPage'; // Ensure the file name is correctly capitalized

function MainLayout() {
  return (
    <div className='MainLayout'>
      <Header />
      <div id="welcome"><Welcome /></div>
      <div id="hero"><Hero /></div>
      <div id="about"><About /></div>
      <div id="cta"><CallToAction /></div>
      <div id="programs"><Programs /></div>
      <div id="enroll"><NewProgramForm /></div>
      <div id="scheduleCall"><ScheduleCallForm /></div>
      <div id="faq"><Faq /></div>
      <Footer />
    </div>
  );
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainLayout />} />
        <Route path="/login" element={<Login />} />
        <Route path="/learningpage" element={<LearningPage />} />
      </Routes>
    </Router>
  );
}

export default App; */
  
