import React from 'react';
import './CallToAction.css';

const CallToAction = () => {
  return (
    <section className="call-to-action">
      <div className="container">
        <h2 className="cta-headline">Ready to Start Your Journey?</h2>
        <p className="cta-subheadline">Join thousands of successful graduates.</p>
        <a href="#enroll" className="cta-button primary">Apply Now</a>
        <a href="#scheduleCall" className="cta-button secondary">Schedule a Call</a>
      </div>
    </section>
  );
};

export default CallToAction;